import React from "react";
import { transformVar } from "@quarkly/atomize";
import { createGlobalStyle } from "styled-components";

const pageStyles = {
    "404": {
        "background": "#070707 url(https://uploads.quarkly.io/6576a43c8d4a0c002027e288/images/vajoner-menu-bg.jpg?v=2023-12-11T06:02:16.859Z) 0% 0% /auto repeat scroll padding-box",
        "font": "16px --fontFamily-googleNunitoSans"
    },
    "index": {
        "background": "#070707 url(https://uploads.quarkly.io/6576a43c8d4a0c002027e288/images/vajoner-menu-bg.jpg?v=2023-12-11T06:02:16.859Z) 0% 0% /auto repeat scroll padding-box",
        "font": "16px --fontFamily-googleNunitoSans"
    }
};

const PageStyles = createGlobalStyle`
    body {
        ${({ styles }) =>
            Object.entries(styles || {}).map(
                ([prop, value]) => `${prop}: ${transformVar(prop, value)};`
            )}
    }
`;
export const GlobalQuarklyPageStyles = ({ pageUrl }) => <PageStyles styles={pageStyles[pageUrl]} />
